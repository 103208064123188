// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const AboutMobile = () => {
  return (
    <>
      {/* header */}
      {/* <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec  mt-0">
        <div class="heading">
          <h1>About Us</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>About Us</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div>
                <h3>What is Cric Crak? </h3>
                <p class="text-justify">
                  <strong>Cric Crak is an emerging online gaming platform</strong> in India that brings various enthralling online games in <strong>Android and iOS</strong> mobile platforms for sports & game lovers. Join our fantasy gaming platform, play your favourite game, and win exciting rewards and cash prizes.
                </p>
                <p class="text-justify">
                  <strong>It is a Game of Skill </strong> where you create a team of real players for an upcoming match and compete with other fans for  <strong> Big Jackpot Wins.</strong>
                </p>

                <h3>GAME OF SKILLS</h3>
                <p class="text-justify">
                  Cric Crak is considered as a “Game of Skill.” The game of skills can be defined as a game wherein the skills of the individuals participating in any online fantasy sports gaming play a dominant role rather than the mere luck of the individuals. The individuals in game of skills use their knowledge, skills, training and attention for participation and winning.
                </p>
                <p class="text-justify">
                  Online fantasy sports gaming is considered to be a legal practice around the world with certain states being an exception. Online fantasy sports gaming is entirely based on the concept of Game of Skills, wherein the users form their own teams and are allocated points on the basis of on-field performance of their chosen players.
                </p>
                <p class="text-justify">
                  Cric Crak is completely legal as it offers services, Fun Features, Program(s) and Contest(s) related to fantasy cricket. The services, contest(s) and program(s) related to online fantasy sports gaming offered by Cric Crak enables its users to create their own team prior the match begins and then the teams are awarded points on the basis of the real-life performance of the players chosen by the users in the team. The individual whose team scores the highest aggregate of points is announced as the winner. The formation of team by the users of Cric Crak is entirely dependent on the skills, knowledge and attention of the users which makes online fantasy sports gaming offered at Cric Crak a game of skills and 100% legal.
                </p>
                <h3>
                  ALIGNMENT WITH THE INDIAN LAWS
                </h3>
                <p class="text-justify">
                  The game of skills such as the online fantasy sports gaming are considered to be legal all over India. Expect certain states such as Sikkim, Assam, Odisha, Telangana, Nagaland and Andhra Pradesh. The Public Gambling Act, 1867
                </p>
                <p class="text-justify">
                  (“PGA, 1867”) is recognized as the primary legality driving the prevalence of gambling in India.
                </p>
                <p class="text-justify">
                  The PGA defines the act of “public gambling” and the keeping of a common gaming house as a criminal and punishable act in India. However, the online fantasy sports gaming is considered to be an exception under the PGA, wherein the provisions and punishments of PGA shall not be applicable to games played by the user using their skills and knowledge as primary tools.
                </p>

                <h3>SIKKIM, ASSAM, ODISHA, TELANGANA, NAGALAND AND ANDHRA PRADESH</h3>
                <p class="text-justify">
                  There are various states in India wherein the laws related to the game of skills are unclear. In this regard, for the websites offering online fantasy sports gaming, it remains unclear whether to offer the services for free or not. The Indian states with no clearance regarding the offering of pay-to-play contests include Sikkim, Assam, Odisha, Telangana, Nagaland and Andhra Pradesh.
                </p>
                <p class="text-justify">
                  In this context, Cric Crak does not permit the users identified as the residents of Sikkim, Odisha, Telangana, Assam, Nagaland and Andhra Pradesh to participate in the pay-to-play contest(s) organized by Cric Crak.
                </p>
                <p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="footer-info">
                  <h3>Cric Crak</h3>
                  <p class="pb-3">
                    <em>
                      Cric Crak is an emerging online gaming platform in
                      India that brings various enthralling online games in
                      Android and iOS mobile platforms for sports & game lovers.
                      Join our fantasy gaming platform, play your favorite game,
                      and win exciting rewards and cash prizes.
                    </em>
                  </p>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="about.html">About us</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="trems.html">Terms of service</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="policy.html">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="fairplay.html">Fair Play</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="howtoplay.html">How to play</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Payment Partners</h4>

                <div class="payments-partner mb-4">
                  <img src="img/payment_partner.png" alt="" class="img-fluid" />{" "}
                </div>

                <p>
                  {" "}
                  <strong>Address: </strong> A108 Adam Street NY 535022, USA{" "}
                </p>
                <p>
                  <strong>Phone:</strong> +1 5589 55488 55{" "}
                </p>

                <p>
                  <strong>Email:</strong> info@example.com{" "}
                </p>

                <div class="social-links mt-3">
                  <a href="#" class="twitter">
                    <i class="bx bxl-twitter"></i>
                  </a>
                  <a href="#" class="facebook">
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a href="#" class="instagram">
                    <i class="bx bxl-instagram"></i>
                  </a>
                  <a href="#" class="youtube">
                    <i class="bx bxl-skype"></i>
                  </a>
                  <a href="#" class="linkedin">
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            Copyright &copy;{" "}
            <strong>
              <span>
                <a href="#">www.Cric Crak.com/</a>
              </span>
            </strong>
          </div> */}
      {/* </div>
      </footer> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
