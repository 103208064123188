import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const PolicyMobile = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      {/* <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec mt-0">
        <div class="heading">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Privacy Policy</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <h3>Privacy Policy</h3> 
              <p class="lh18 pdTop15">
              All required information is specific and based on the kind of Game/ Facility the User wishes to participate in or access, and will be utilized for the purpose of providing services, including but not limited to the Facility, requested by the User. The information as supplied by the Users enables us to improve the Facilities and provide you the most user-friendly game experience. Cric Crak may also share such information with affiliates and third parties in limited circumstances, including for the purpose of providing services requested by the User, complying with legal process, preventing fraud or imminent harm, and ensuring the security of our network and services.
              </p>
               
              <p class="lh18 pdTop15">
                <strong>Use of Cookies:</strong>
              </p>
              <p class="lh18 pdTop15">
              Cric Crak's web servers automatically collect limited information about User's computer's connection to the Internet, including User's IP address, when the User visits the Portal. (User's IP address is a number that lets computers attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. Cric Crak uses this information to deliver its web pages to Users upon request, to tailor its Portal to the interests of its users, to measure traffic within the Portal and let advertisers know the geographic locations from where Cric Crak's visitors come.
              </p>
               
              <p class="lh18 pdTop15">
                <strong> Information Security: </strong>
              </p>
              <p class="lh18 pdTop15">
              We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Cric Crak has a comprehensive information security program and information security policies which contain managerial, technical, operational and physical security control measures adopted by Cric Crak for the protection of Sensitive Personal Date and Other Personal Information.
              </p>
              <p class="lh18 pdTop15">
              Information gathered by Cric Crak is stored securely using several information security applications including firewalls. However, security is always relative and Cric Crak cannot guarantee that its security measures are absolute and cannot be breached. Data which is transmitted over the Internet is inherently exposed to security risks or threats. For instance, information transmitted via chat or share any information with them.
              </p>
              <p class="lh18 pdTop15">
              Cric Crak strongly recommends the users not to trust, and respond to such mails, blogs, websites etc. and also not to share any credit /debit card details with them.
              </p>
 
              <p class="lh18 pdTop15">
              Any doubts or comments with respect to this Privacy Policy can be sent to our mail : 
              <br/>
              <a href="mailto:criccrack.tech@gmail.com" > criccrack.tech@gmail.com </a>
              </p> 
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
