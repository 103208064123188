import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Faq = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Faq   </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Faq  </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                      <p className="mb-2" > <b> Q: What is the maximum number of teams can you form in a match?</b></p>
                      <div class="t_c_content mb-4 ">
                        <p class="pdLt20"> 
                        Ans.- The maximum number of teams you can create in a match is 7. However, the maximum number of teams may vary as per different matches, type of contests and contest size.
                        </p> 
                      </div>
                      <hr/> 
                      <p className="mb-2" >
                       <b>What happens if I make changes just when the match starts?</b>
                      </p>
                      <div class="t_c_content  mt-3 mb-4">  
                        <p>
                        The changes will not be saved as the contest freezes when the match starts.
                        </p> 
                      </div>
                      <hr/> 
                      <p className="mb-2" >
                      <b>What happens if a match is cancelled?</b>
                      </p>
                      <div class="t_c_content  mt-3  mb-4   "> 
                        <p>
                        If a match is cancelled or abandoned, we refund any entry fee the user paid to join a contest. This will happen the moment we receive a confirmation that the match has been abandoned or cancelled.
                        </p> 
                      </div> 

                      <hr/> 

                       <p className="mb-2" >
                       <b>If a match is delayed, can I make changes to the team?</b>
                      </p>
                      <div class="t_c_content  mt-3  mb-4 "> 
                      
                      <p className="mb-0" > Yes! You can make as many changes in your teams before the scheduled match start time on our app. If there’s a delay, we ensure that we postpone the match start time on our app basis the information available on public domains.</p>

                      </div>

                      <hr/>

                      <p className="mb-2" >
                      <b> If rains interrupt a match and it continues the next day from the same over, then what happens?</b>
                      </p>
                      <div class="t_c_content  "> 
                      
                      <p>  
                      You cannot make changes to your team and the score of the players continues keeping up with the match.
                      </p>


                      </div>

                     
                      


                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
