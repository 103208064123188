import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { About } from "./ui/About";
import { Contact } from "./ui/Contact";
import { FairPlay } from "./ui/FairPlay";
import { HowToPlay } from "./ui/HowToPlay";
import { Policy } from "./ui/Policy";
import { TermsNConditions } from "./ui/TermsNConditions";
import { Home } from "./ui/Home";
import { RefundPolicy } from "./ui/RefundPolicy";
import { ContactMobile } from "./ui/ContactMobile";
import { HowToPlayMobile } from "./ui/HowToPlayMobile";
import { AboutMobile } from "./ui/AboutMobile";
import { TermsNConditionsMobile } from "./ui/TermsNConditionsMobile";
import { PolicyMobile } from "./ui/PolicyMobile";
import { SubstitutionRules } from "./ui/SubstitutionRules";
import { WalletSection } from "./ui/WalletSection";
import { Faq } from "./ui/Faq";
import { GSBCategorization } from "./ui/GSBCategorization";
import { SubstitutionRulesMobile } from "./ui/SubstitutionRulesMobile";
import { WalletSectionMobile } from "./ui/WalletSectionMobile";
import { RefundPolicyMobile } from "./ui/RefundPolicyMobile";
import { FairPlayMobile } from "./ui/FairPlayMobile";
import { FaqMobile } from "./ui/FaqMobile";
import { GSBCategorizationMobile } from "./ui/GSBCategorizationMobile";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/aboutMobile" element={<AboutMobile />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/contactMobile" element={<ContactMobile />} />

        <Route path="/fairPlay" element={<FairPlay />} /> 
        <Route path="/fairPlaymobile" element={<FairPlayMobile />} />

        <Route path="/howToPlay" element={<HowToPlay />} />
        <Route path="/howToPlayMobile" element={<HowToPlayMobile />} />

        <Route path="/policy" element={<Policy />} />
        <Route path="/policyMobile" element={<PolicyMobile />} />

        <Route path="/termsNConditions" element={<TermsNConditions />} />
        <Route path="/termsNConditionsMobile" element={<TermsNConditionsMobile />} />

        <Route path="/refund_policy" element={<RefundPolicy />} />
        <Route path="/refund_policymobile" element={<RefundPolicyMobile />} />

        <Route path="/Substitution_rules" element={<SubstitutionRules />} />
        <Route path="/Substitution_rulesmobile" element={<SubstitutionRulesMobile />} />

        <Route path="/walletsection" element={<WalletSection />} />
        <Route path="/walletsectionmobile" element={<WalletSectionMobile />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/faqmobile" element={<FaqMobile />} />
        
        <Route path="/gsb_categorization" element={<GSBCategorization />} />
        <Route path="/gsb_categorizationmobile" element={<GSBCategorizationMobile />} />
      </Routes>
    </>
  );
}

export default App;
