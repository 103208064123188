// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const RefundPolicyMobile = () => {
  return (
    <> 

      <section class="inner_hero_sec   mt-0">
        <div class="heading">
          <h1>Cancellation/Refund Policy    </h1>
        </div>
      </section>

      <main id="main"> 
      <section class="inner-page">
          <div class="container">
            <div class="content">
              <h4 className="mb-3" ><b>Cancellation/Refund Policy</b></h4>
              <p>All deposits and purchases made and fees paid on Cric Crak to play or in connection with Fantasy Cricket are final. All transactions are final. We recognize that customer satisfaction is extremely important to us, so only error-oriented transaction cases shall be reviewed.</p>
              <p>If you are not fully satisfied with any deposit/purchase made or fee paid on Cric Crak and feel there is an 'Error’ in the transaction, please let us know about that within 3 days from the transaction date, and we shall certainly review the transaction and determine the resolution at our own discretion.</p>
              <p>Cric Crak reserves the right to cancel any match(es) for any reason, with or without disclosure of the reason, and refund all fees paid for such matches by any and all users within 1-3 business days.</p>
            </div>
          </div>
        </section>
      </main>
       
    </>
  );
};
