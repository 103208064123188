// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const FaqMobile = () => {
  return (
    <> 

      <section class="inner_hero_sec  mt-0">
        <div class="heading">
          <h1>  Faq  </h1>
        </div>
      </section>

      <main id="main"> 
      <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                      <p className="mb-2" > <b> Q: What is the maximum number of teams can you form in a match?</b></p>
                      <div class="t_c_content mb-4 ">
                        <p class="pdLt20"> 
                        Ans.- The maximum number of teams you can create in a match is 7. However, the maximum number of teams may vary as per different matches, type of contests and contest size.
                        </p> 
                      </div>
                      <hr/> 
                      <p className="mb-2" >
                       <b>What happens if I make changes just when the match starts?</b>
                      </p>
                      <div class="t_c_content  mt-3 mb-4">  
                        <p>
                        The changes will not be saved as the contest freezes when the match starts.
                        </p> 
                      </div>
                      <hr/> 
                      <p className="mb-2" >
                      <b>What happens if a match is cancelled?</b>
                      </p>
                      <div class="t_c_content  mt-3  mb-4   "> 
                        <p>
                        If a match is cancelled or abandoned, we refund any entry fee the user paid to join a contest. This will happen the moment we receive a confirmation that the match has been abandoned or cancelled.
                        </p> 
                      </div> 

                      <hr/> 

                       <p className="mb-2" >
                       <b>If a match is delayed, can I make changes to the team?</b>
                      </p>
                      <div class="t_c_content  mt-3  mb-4 "> 
                      
                      <p className="mb-0" > Yes! You can make as many changes in your teams before the scheduled match start time on our app. If there’s a delay, we ensure that we postpone the match start time on our app basis the information available on public domains.</p>

                      </div>

                      <hr/>

                      <p className="mb-2" >
                      <b> If rains interrupt a match and it continues the next day from the same over, then what happens?</b>
                      </p>
                      <div class="t_c_content  "> 
                      
                      <p>  
                      You cannot make changes to your team and the score of the players continues keeping up with the match.
                      </p>


                      </div>

                     
                      


                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
       
    </>
  );
};
