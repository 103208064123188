import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section class="hero p-0 ">
        <div class="hero-main">
          <img
            src="img/hero-bg.png"
            class="img-fluid w-100 desktop-hero d-none d-sm-none d-md-block"
            alt=""
          />
          <img
            src="img/hero-mobile-banner.png"
            class="img-fluid w-100 desktop-hero d-block d-sm-block d-md-none"
            alt=""
          />
        </div>
      </section>

      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= Counts Section ======= --> */}
        <section id="counts" class="counts">
          <div class="container">
            <div class="row" data-aos="fade-up">
              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-emoji-smile"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    1.5 MILLION+
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div class="count-box">
                  <i class="ri-currency-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    125 CRORES+
                  </span>
                  <p>Cash Winnings</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-focus-2-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1463"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    25 LAKHS+
                  </span>
                  <p>Contests Played</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-gift-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="15"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    10 THOUSAND+
                  </span>
                  <p>Giveaway</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /<!-- End Counts Section --> */}

        {/* <!-- ======= About Section ======= --> */}
        <section id="Downloading" class="Downloading pb-0">
          <div class="container">
            <div class="section-title aos-init aos-animate">
              <h2>Downloading the</h2>
              <p>Cric Crak App is Easy!</p>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">1</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open www.criccrak.in &amp; click on{" "}
                    <strong>“Download for Android”</strong> button
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">2</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open CricCrak.com &amp; Wait for the apk to download
                    & click on<strong>“Open”</strong>
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">3</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Click <strong>“Allow for this Source”</strong> when prompted
                    & then click on Install
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title aos-init aos-animate">
                  <h2>Enjoy Superfast Winnings with</h2>
                  <p> Super-easy Gaming</p>
                </div>
              </div>

              <div
                class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                data-aos="fade-right"
              ></div>

              <div
                class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
                data-aos="fade-left"
              >
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div class="icon">
                    <i class="">1</i>
                  </div>
                  <h4 class="title">
                    <a href="">Select A Match</a>
                  </h4>
                  <p class="description">
                    Choose an upcoming match that you want to play{" "}
                  </p>
                </div>
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon">
                    <i class="">2</i>
                  </div>
                  <h4 class="title">
                    <a href=""> Create Team</a>
                  </h4>
                  <p class="description">
                    Use your skills to pick the right players
                  </p>
                </div>

                <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div class="icon">
                    <i class="">3</i>
                  </div>
                  <h4 class="title">
                    <a href="">Join Contest</a>
                  </h4>
                  <p class="description">
                    Choose between different contests and win money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="welcome-sec pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 order-md-2">
                <div class="content">
                  <div class="heading">
                    <h2>
                      A Super <span class="head-text">Welcome Bonus</span>
                    </h2>
                    <h6>DOWNLOAD &amp; REGISTER TO GET</h6>
                    <h5>
                      <span>₹100</span> Sign up bonus
                    </h5>
                  </div>
                  <div class="welcome_btn mt-4 mb-3">
                    <a href="#">
                      <img
                        src="img/andrid-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="img/apple-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    <small>
                      *This game involves an element of financial risk and may
                      be addictive. please play responsibly and at your own
                      risk.
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="welcome_img">
                  <img src="img/about-img.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= F.A.Q Section ======= --> */}
        <section id="faq" class="faq section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </div>

            <div class="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What is Fantasy Cricket?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    class="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    class="collapsed"
                  >
                    How To Play Fantasy Cricket and Win Cash Daily?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on Cric Crak , you need to go through the below steps:
                      <br /> -Download the Cric Crak app & install it on
                      your mobile.
                      <br /> -Make a registration via your mobile number or
                      email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br /> -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br /> -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    class="collapsed"
                  >
                    What is IPL Fantasy League?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      IPL fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    class="collapsed"
                  >
                    How to Play IPL Fantasy?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on Cric Crak, you need to go through the below steps:
                      <br />
                      -Download the Cric Crak app & install it on your
                      mobile.
                      <br />
                      -Make a registration via your mobile number or email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br />
                      -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br />
                      -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    class="collapsed"
                  >
                    Beyond Cricket - Fantasy Football And Much More
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Cric Crak is the most trusted and secured online
                      fantasy sports gaming platform that strictly follows
                      fantasy sports and gaming rules & regulations and runs OFS
                      contests based on fair game policy. Security is our
                      priority and we are very much committed to providing
                      secure services by safeguarding user’s personal
                      information.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <!-- End F.A.Q Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" class="contact">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div class="row align-items-start">
              <div class="col-lg-12" data-aos="fade-right" data-aos-delay="100">
                <div class="info row align-items-start">
                  <div class="email  col-md-4 mt-md-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>criccrack.tech@gmail.com</p>
                  </div>

                  <div class="phone  col-md-4  mt-md-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 9625233933</p>
                  </div>
                  <div class="address col-md-4">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Bestech Park View Residency, 1402/T-1, Palam Vihar, Gurgaon, Haryana 122017</p>
                  </div>
                </div>
              </div>

              {/* <div
                class="col-lg-8 mt-5 mt-lg-0"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  class="php-email-form"
                >
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>Cric Crak</h3>
              <p class="pb-3"><em>Cric Crak is an emerging online gaming platform in India that brings various enthralling online games in Android and iOS mobile platforms for sports & game lovers. Join our fantasy gaming platform, play your favorite game, and win exciting rewards and cash prizes.</em></p>
              
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="index.html">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="about.html">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="trems.html">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="policy.html">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="fairplay.html">Fair Play</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="howtoplay.html">How to play</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="contact.html">Contact</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Payment Partners</h4>
            
            <div class="payments-partner mb-4"><img src="img/payment_partner.png" alt="" class="img-fluid"/></div>

                <p> <strong>Address: </strong> A108 Adam Street
                NY 535022, USA </p>
                <p><strong>Phone:</strong> +1 5589 55488 55 </p>

                <p><strong>Email:</strong> info@example.com </p>
              
              <div class="social-links mt-3">
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="#" class="youtube"><i class="bx bxl-skype"></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              </div>



          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
         Copyright &copy; <strong><span><a href="#" >www.Cric Crak.com/</a></span></strong>
      </div>
    </div>
  </footer> */}
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
