// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const GSBCategorizationMobile = () => {
  return (
    <> 

      <section class="inner_hero_sec  mt-0">
        <div class="heading">
          <h1>  GSB Categorization  </h1>
        </div>
      </section>

      <main id="main"> 
      <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                      <h4> GSB Categorization  </h4>
                      <div class="t_c_content mb-4 mb-md-5  ">
                        <p class="pdLt20"> 
                        Our special and meticulously designed feature of Gold/ Silver/ Bronze Categorization will save you by your own cricketing knowledge. Now your Risky Punts will reward you more than ever. 

                        
                        </p>
                        
                        <p class="pdLt20"> 
                          <i>The more Bronze players you have, the riskier the team it gets. While more Gold players represent Safe team, Silver means somewhere in between.</i>
                        </p>
                        <p>
                        A player's total fantasy points get multiplied by his/her respective multiplier depending on his/her respective category. 
                        </p>
                      </div>


                      <p>
                       Here are the <b> Multipliers:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                         <b>Gold - 1.1x</b>
                        </p>
                        <p>
                         <b>Silver - 1.2x</b>
                        </p>
                        <p>
                         <b>Bronze - 1.3x</b>
                        </p>


                        <p>Example - A Batter scores 100 fantasy points. If he is Gold, he gets 110 points(100*1.1) . If he is Silver, he gets 120 points(100*1.2) . If he is Bronze, he gets 130 points(100*1.3). </p>

                        <p>
                        And Suppose the same batter to be your Team's Captain means 2x additional points. 
                        </p>

                        <p>
                        That is, 
                        </p>


                        <p> If he's Gold, he gets 100*1.1*2 =220 points </p>
                        <p> If he's Silver, he gets 100*1.2*2 = 240 points</p> 
                        <p> If he's Bronze, he gets 100*1.3*2 =260 points </p>

                      </div>



                       

                     
                      


                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
       
    </>
  );
};
