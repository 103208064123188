// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const SubstitutionRulesMobile = () => {
  return (
    <>
      {/* header */}
      {/* <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec   mt-0">
        <div class="heading">
          <h1>Substitution Rules   </h1>
        </div>
      </section>

      <main id="main">
        

        <section class="inner-page">
        <div class="container">
            <div class="content mb-5 ">
              <p>
                Your Substitute(s) will come into action when your already chosen player(s) is not a part of the playing XI
              </p>
              <p> 
                A player with status NOT PLAYING in your playing XI is replaced with a Substitute with status PLAYING
              </p>
              <p>
              You can select upto 4 Substitutes in the order of priority as <b>SUB1, SUB2, SUB3, SUB4.  </b>
              </p>
              <p>
              A player can be replaced with a Substitute of a different Player Type. For example, an all-rounder can replace a batsman if all the team-making rules are followed. 
              </p>
            </div>

            <div class="content mb-5 ">
              <h5 className="mb-4" >
              <b>Team-making rules applicable even after Substitution:</b>
              </h5>
              <p className="mb-2" >
              Your team should have <b>1-8 wicket-keepers, 1-8 batters, 1-8 all-rounders and 1-8 bowlers.</b>
              
              </p>
              <p>
              You can select a <b>maximum of 10 players from one team.</b>
              </p>
            </div>

            <div class="content mb-5 ">
            <h5 className="mb-4" >
              <b>How Substitutions Work?</b>
            </h5>
              <div className="row" >    
                <div className="col-lg-4" >
                 <h4>
                  <b>  Step 1:  </b>
                 </h4>
                 <p>  Select Upto 4 players as Substitutes in addition to 11 players in your team in the order 
                  of priority as Sub1, Sub2, Sub3, Sub4. However, it is not necessary to 
                  fill all 4 substitute places. The Substitutions will take place accordingly.   </p>
                </div>
                <div className="col-lg-4" >
                 <h4>
                  <b>  Step 2:  </b>
                 </h4>
                 <p>  A player from your team is not a part of the Playing XI, that is, he/she has the status NOT PLAYING.   </p>
                </div>
                <div className="col-lg-4" >
                 <h4>
                  <b>  Step 3:  </b>
                 </h4>
                 <p> Substitutions will be automatically initiated and the system will replace your NOT 
                  PLAYING player with a substitute who is PLAYING in the match, going by the priority explained below :   </p>
                </div>
              </div>
            </div>


            

            <div class="content mb-5 ">
              <h5 className="mb-4" >
              <b> First preference for Captain and Vice Captain Substitutes : </b>
              </h5>
              <p className="" >
              -If Captain is not playing, Vice-Captain will become Captain. 
              
              </p> 
              <p className="" >
              -If Vice-Captain is not playing or in case where Vice-Captain becomes Captain, Selected player with the Highest Avg Points till that stage will be selected. (Avg Points are exclusive of GSB Categorization and will provide level playing field for each player). 
              
              </p> 
              <p className="" >
              -If any player(s) selected in the playing 11 is not playing, automatically Substitutions will take place in their place(s) on the order of substitution priorities. 
              
              </p> 
              <p className="" >
              -If there is more than one NOT PLAYING member in the team, then the Player with the lowest Avg Points till that stage will be replaced first according the rest of the Substitutions will also take place. If the Avg Points are same, then Substitutions will be done in the following order :
              
              </p> 
              <p className="" >  Batters will be replaced first   </p>
              <p className="" > All-Rounders will be replaced next  </p>
              <p className="" > Wicket-Keepers will be replaced next   </p>
              <p className="" >Bowlers will be replaced at last   </p> 

              <p>
              If at all, all 4 substitutions are not enough to replace the not playing players, then the player with the Highest Avg Points till that stage will remain in the team with NOT PLAYING tag. 
              </p>


            </div>


            <div class="content mb-5 ">
              <h5 className="mb-4" >
              <b> Retired out / Retired hurt /Concussion case : </b>
              </h5>
              <p className="" >
              Players retired out, retired hurt or concussed will only be rewarded for the points they earn on their in-match performance till the time they are taken out. Once the taken out players don't have any further role in a match, they will be as good as the status of NOT PLAYING. 
              
              </p> 
              <p className=" " >
                
                <b>Simple rule</b> - Your selected 11 players along with 4 substitutes only will be rewarded with their earned points. If somehow they no longer have a role in the match, their points earned till that stage only will be considered and no further points will be awarded.

              </p>


            </div>





            
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

       
    </>
  );
};
